import HomeIndex from 'src/pages/home-index';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { LocaleStaticProps } from 'src/utils/types/i18n';

export async function getStaticProps({ locale }: { locale: string }): Promise<LocaleStaticProps> {
  return {
    props: {
      // Load in i18n translations
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default HomeIndex;
