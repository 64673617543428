import { useEffect } from 'react';
import { useRouter } from 'next/router';

function HomeIndex(): null {
  const router = useRouter();

  useEffect(() => {
    void router.replace('/products');
  }, [router]);

  return null;
}

export default HomeIndex;
